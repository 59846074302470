<template>
   <div id="questionsManager">
      <div id="wrapper">
         <Header/>
         <Navigation v-bind:options="navigationOptions"/>
         <div class="flex-grid">
            <div class="col-sm">
               <ListNav
                  v-bind:items="tags"
                  v-bind:selectItemCallback="tagSelectCallback"
                  v-bind:removeItemCallback="tagRemoveCallback"
                  v-bind:sortCallback="sortTagsCallback"
                  v-bind:disabled="showEditor"
               />
            </div>
            <div
               v-if="!showEditor"
               class="col-2"
            >
               <QuestionList
                  v-bind:title="listTitle"
                  v-bind:questions="questions"
                  v-bind:isLoading="isLoading || questionsAreRefreshing"
                  v-bind:questionClickCallback="handleQuestionClick"
               />
               <Pagination
                  v-if="!isLoading"
                  v-bind:show="!questionsAreRefreshing"
                  v-bind:itemCount="Object.keys(questions).length"
                  v-bind:totalItems="currentTagQuestionCount"
                  v-bind:pageChangeCallback="pageChangeCallback"
               />
            </div>
            <div
               v-if="showEditor"
               class="col-2"
            >
               <QuestionEditor
                  v-bind:tags="tags"
                  v-bind:currentTag="tags.find(val => val.id === currentTag)"
                  v-bind:question="currentQuestion"
                  v-bind:previousViewCallback="previousViewCallback"
                  v-bind:refreshQuestionsForTag="refreshQuestionsForTag"
                  v-bind:questionAlreadyExists="true"
               />
            </div>
         </div>
         <CopyrightFooter/>
      </div> <!-- wrapper -->
   </div>
</template>

<script>
   // Import functions
   import { inject } from 'vue'
   // import { makeToast } from '@/components/Toast.js'
   import { getTags } from '@/api/tags.js'
   import { getQuestionsForTag } from '@/api/questions.js'
   import { getNavigationOptions } from '@/api/navigation.js'
   // UI components
   import Pagination from '@/components/Pagination.vue'
   import Header from '@/components/Header.vue'
   import CopyrightFooter from '@/components/CopyrightFooter.vue'
   import QuestionList from '@/components/QuestionList.vue'
   import QuestionEditor from '@/views/questions/QuestionEditor.vue'
   import ListNav from '@/components/ListNav.vue'
   import Navigation from '@/components/Navigation.vue'
   // Export component
   export default {
      name: 'QuestionsManager',
      props: {
      },
      data: () => {
         return {
            tags: [],
            questions: {},
            pagination: { // track pagination here, for question refresh functionality
               page: Pagination.constants.defaultPage,
               pageSize: Pagination.constants.defaultPageSize,
            },
            currentTag: null,
            currentQuestion: null,
            showEditor: false,
            isLoading: false,
            questionsAreRefreshing: true,
            listTitle: 'Questions',
         }
      },
      computed: {
         currentTagQuestionCount: function() {
            // Null-safe unless tag list is modified
            const tempTag = this.tags.find(val => val.id === this.currentTag)
            return tempTag ? tempTag.count : 0
         },
         navigationOptions: function() {
            return getNavigationOptions(this.router)
         },
      },
      components: {
         'Header': Header,
         'CopyrightFooter': CopyrightFooter,
         'ListNav': ListNav, // vertical list for intra-view actions
         'Navigation': Navigation, // horizontal list for inter-view navigation
         'QuestionList': QuestionList,
         'QuestionEditor': QuestionEditor,
         'Pagination': Pagination,
      },
      methods: {
         resetPagination() {
            this.pagination = {
               page: Pagination.constants.defaultPage,
               pageSize: Pagination.constants.defaultPageSize,
            }
         },
         previousViewCallback() {
            this.showEditor = false
            // Reset current question
            this.currentQuestion = null
         },
         handleQuestionClick(id) {
            this.currentQuestion = this.questions.find(val => val.id === id)
            this.showEditor = true
         },
         pageChangeCallback(page, pageSize) {
            console.log('pageChangeCallback', page,pageSize)
            this.pagination = {
               page: page,
               pageSize: pageSize
            }
            this.refreshQuestionsForTag()
         },
         refreshQuestionsForTag() {
            /*
            * TODO: this is currently bugged - refresh should be of current page
            * and this will be called with default values above
            */
            console.log('refreshQuestionsForTag', this.pagination)
            // Reset questions and show loading message
            this.questions = {}
            this.questionsAreRefreshing = true
            // Request paginated questions for tag from API
            const params = {
               id: this.currentTag,
               axios: this.axios,
               page: this.pagination.page,
               pageSize: this.pagination.pageSize,
               callback: (resData) => {
                  // Store questions in master list by key
                  this.addQuestionsToList(this.currentTag, resData.data)
                  //
                  if(this.currentQuestion) {
                     this.currentQuestion = this.questions.find(val => val.id === this.currentQuestion.id)
                     console.log("bleep")
                  }
                  // Clear loading message in list
                  this.questionsAreRefreshing = false
               }
            }
            // Call API
            getQuestionsForTag(params)
         },
         sortTagsCallback(method, direction) {
            // console.log('sortTagsCallback', method, direction)
            if(method === 'count') {
               // Sort by item count
               this.tags = this.tags.sort((val, next) => {
                  return direction === 1
                     ? val.count > next.count
                     : val.count < next.count
               })
            } else if(method === 'alphabet') {
               // Sort alphabetically
               this.tags = this.tags.sort((val, next) => {
                  // Direction (-1, 1) modifies compared result (-1, 0, 1)
                  return direction * val.name.localeCompare(next.name)
               })
            }
         },
         tagSelectCallback(id) {
            // Request questions for tag from API
            const params = {
               id: id,
               axios: this.axios,
               callback: (resData) => {
                  // Store questions in master list by key
                  this.addQuestionsToList(id, resData.data)
                  // Set current tag
                  this.currentTag = id
                  // Reset flags
                  this.isLoading = false
                  this.questionsAreRefreshing = false
               }
            }
            // Set loading flag
            this.isLoading = true
            // Reset questions
            this.questions = []
            this.resetPagination()
            // Make API call
            getQuestionsForTag(params)
         },
         tagRemoveCallback() {
            // delete this.questions[id]
            this.questions = []
         },
         addQuestionsToList(id, data) {
            // this.questions[id] = data
            this.questions = data
         }
      },
      setup() {
         // Inject axios and provide as prop to component for use with API calls
         const $axios = inject('$axios')
         // Same with router
         const $router = inject('$router')
         return {
            axios: $axios,
            router: $router
         }
      },
      beforeCreate() {
         let params = {
            callback: (resData) => {
               // Process data for generic ListNav
               const filteredTags = resData.data.map(val => {
                  return {
                     id: val.tag_id,
                     name: val.name,
                     count: val.number_of_questions
                  }
               })
               // Store tag list
               this.tags = filteredTags
            }
         }
         // Make request for tags
         getTags(params)
      }
   }
</script>

<style scoped>
   #questionsManager {
      text-align: left;
      margin: 1em 0 0;
   }
   #wrapper {
      max-width: 1500px;
      margin: 0 auto;
      position: relative;
   }
</style>
