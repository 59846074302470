<template>
   <div class="highlight-border light-bg">
      <h3>
         Tags
         <!-- sorting tools - by # of items, or alphabetical by name -->
         <FontAwesomeIcon
            :icon="hashtagIcon"
            :class="`sort-icon ${sortBy !== 'count' ? 'muted' : '' }`"
            v-on:click="handleSortByClick('count')"
         />
         <FontAwesomeIcon
            :icon="fontIcon"
            :class="`sort-icon ${sortBy !== 'alphabet' ? 'muted' : '' }`"
            v-on:click="handleSortByClick('alphabet')"
         />
         <div class="sort-label muted">
            Sort
         </div>
      </h3>
      <ul class="list-nav">
         <li
            v-for="item in visibleItems"
            v-on:click="handleItemClick"
            :id="`item-${item.id}`"
            :key="item.name"
            :class="Object.keys(selectedItems).includes(`item-${item.id}`) ? 'selected' : ''"
         >
            {{ item.name }}
            <div class="list-count muted">
               {{ item.count }}
            </div>
         </li>
      </ul>
      <div
         v-if="items.length === 0"
         class="info-message site-bg"
      >
         Loading...
      </div>
      <div id="expandControls">
         <div id="expandCount">
            {{ notVisibleItemCount }} more
         </div>
         <div class="expand-icon">
            <FontAwesomeIcon
               :icon="chevronDownIcon"
               v-on:click="expandViewableList"
            />
         </div>
         <div class="expand-icon">
            <FontAwesomeIcon
               :icon="expandIcon"
               v-on:click="blowUpViewableList"
            />
         </div>
      </div>
   </div>
</template>

<script>
   // Import UI components
   import { faFont, faHashtag,
      faChevronDown, faExpand } from '@fortawesome/free-solid-svg-icons'
   // Define component-level constants
   const visibleItemCountIncrement = 40
   // Export component
   export default {
      name: 'ListNav',
      props: {
         items: {
            type: Object,
            default: () => {}
         },
         selectItemCallback: {
            type: Function,
            default: () => {}
         },
         removeItemCallback: {
            type: Function,
            default: () => {}
         },
         sortCallback: {
            type: Function,
            default: () => {}
         },
         disabled: {
            type: Boolean,
            default: () => {}
         },
      },
      data: () => {
         return {
            selectedItems: {},
            sortBy: 'count',
            sortDirection: 1, // ascending
            fontIcon: faFont,
            hashtagIcon: faHashtag,
            chevronDownIcon: faChevronDown,
            expandIcon: faExpand,
            visibleItemCount: visibleItemCountIncrement // default
         }
      },
      computed: {
         visibleItems() {
            return this.items.slice(0, this.visibleItemCount)
         },
         notVisibleItemCount() {
            return Math.max(this.items.length - this.visibleItemCount, 0)
         }
      },
      methods: {
         blowUpViewableList() {
            // Increase # visible items to max
            this.visibleItemCount = this.items.length
         },
         expandViewableList() {
            // Increase # of visible items in list
            this.visibleItemCount = this.visibleItemCount + visibleItemCountIncrement
         },
         handleSortByClick(method) {
            // Reverse direction if no method change
            if(method === this.sortBy) {
               this.sortDirection = -this.sortDirection
            } else {
               // Set name of sort method
               this.sortBy = method
            }
            this.sortCallback(this.sortBy, this.sortDirection)
         },
         handleItemClick(e) {
            // Disabled guard
            if(this.disabled) return
            const id = e.target.id
            // Return only bare ID
            const bareId = parseInt(id.replace('item-', ''))
            // Check if already selected
            const existing = Object.keys(this.selectedItems)
               .find(val => val === id)
            if(existing) {
               // Toggle - remove
               delete this.selectedItems[id]
               this.removeItemCallback(bareId)
            } else {
               // Check if one already selected - this can be changed to multi-select by removing this
               if(Object.keys(this.selectedItems).length > 0) {
                  this.selectedItems = {}
               }
               // Toggle - select
               this.selectedItems[id] = true
               this.selectItemCallback(bareId)
            }
         },
      },
   }
</script>

<style scoped>
   ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;
      border-top-style: solid;
      border-top-width: 2px;
   }
   ul li {
      padding: 0.6em 0.5em 0.3em;
      border-bottom: 1px solid;
      background-color: #fff;
   }
   ul li:hover {
      background-color: #f7f7f7;
   }
   ul li.selected {
      background-color: #aedaf0;
   }
   h3 {
      text-align: left;
      font-size: 1.5em;
      padding: 0.5em 1em;
      margin: 0
   }
   #expandControls {
      text-align: right;
      font-size: 1.1em;
      padding: 0 0.5em;
      border-top-style: solid;
      border-top-width: 1px;
   }
   #expandControls:hover {
      background-color: #aedaf0;
   }
   #expandCount {
      float: left;
      position: relative;
      top: 1em;
   }
   .expand-icon {
      display: inline-block;
      font-size: 2.2em;
      position: relative;
      top: 0.2em;
      margin-left: 0.5em;
   }
</style>
